import {
  ConfigId,
  Configuration,
  ConfigurationItem,
  ConfiguredDepart,
  Enveloppe,
  Nomenclature,
  NomenclatureItem,
  Reference,
  TarifPublic,
} from '@depagne/types';

export function generateNomenclature(config: Configuration, reduction: number, tarifPublicData: TarifPublic[]): Nomenclature {
  const configElectronic = config.details;
  const configList = config.details.configurationItems;
  let nomenclature: NomenclatureItem[] = [];

  const supportTableautin = getConfigItem(configList, 'supportTableautin').value;
  if (supportTableautin.id === 'enveloppe') {
    const materielEnveloppe = getConfigItem(configList, 'materielEnveloppe').value;
    nomenclature = addLineToNomenclature(materielEnveloppe.Ref1, nomenclature, tarifPublicData);
    nomenclature = addLineToNomenclature(materielEnveloppe.Ref2, nomenclature, tarifPublicData);
    nomenclature = addLineToNomenclature(materielEnveloppe.Ref3, nomenclature, tarifPublicData);
    nomenclature = addLineToNomenclature(materielEnveloppe.Ref4, nomenclature, tarifPublicData);
    nomenclature = addLineToNomenclature(materielEnveloppe.Ref5, nomenclature, tarifPublicData);
  } else if (supportTableautin.id === 'platine') {
    // used only in reverse enveloppe
    const materielEnveloppe = getConfigItem(configList, 'materielEnveloppe').value;
    console.log(config.details.configurationItems);
    console.log(materielEnveloppe);
    nomenclature = addLineToNomenclature(materielEnveloppe['REMISEA NEUF - PLATINE COMMANDE'], nomenclature, tarifPublicData);

    const materielPlatineComptage = getConfigItem(configList, 'materielPlatineComptage').value;
    if (materielPlatineComptage.Ref1) {
      nomenclature = addLineToNomenclature(materielPlatineComptage.Ref1, nomenclature, tarifPublicData);
      nomenclature = addLineToNomenclature(materielPlatineComptage.Ref2, nomenclature, tarifPublicData);
    }
  } else if (supportTableautin.id === 'sans_support') {
    // used only in reverse enveloppe
  }
  // In case of S20 we also need one with an enveloppe
  const materielPlatineCommande = getConfigItem(configList, 'materielPlatineCommande').value;
  nomenclature = addLineToNomenclature(materielPlatineCommande?.Reference, nomenclature, tarifPublicData);

  const disjoncteurBranchementBesoin = getConfigItem(configList, 'disjoncteurBranchementBesoin').value;

  if (disjoncteurBranchementBesoin.id === 'oui') {
    const disjoncteurBranchement = getConfigItem(configList, 'disjoncteurBranchement').value;

    if (disjoncteurBranchement.id) {
      const { name, price, surdevis } = getTarifPublicData(disjoncteurBranchement.id, tarifPublicData);
      nomenclature.push({
        reference: disjoncteurBranchement.id,
        designation: name,
        quantity: 1,
        price,
        surdevis,
      });
    }
  }

  if (configElectronic.selectedTableautin && configElectronic.selectedTableautin.id) {
    const { name, price, surdevis } = getTarifPublicData(configElectronic.selectedTableautin.id, tarifPublicData);
    nomenclature.push({
      reference: configElectronic.selectedTableautin.Reference,
      designation: name,
      quantity: 1,
      price,
      surdevis,
    });

    const departs = getConfigItem(configElectronic.configurationItems, 'departs').value as ConfiguredDepart[];
    const interDifferentielMono = Array.from({ length: configElectronic.selectedTableautin.NbRangee }).map(() => false);
    const interDifferentielTetra = Array.from({ length: configElectronic.selectedTableautin.NbRangee }).map(() => false);
    departs.forEach((depart, currentIndex) => {
      addLineToNomenclature(depart.Reference, nomenclature, tarifPublicData, {
        Reference: depart.Reference,
        Designation: depart.Designation,
      });

      if (depart.besoinBornier && depart.kitBornier) {
        addLineToNomenclature(depart.kitBornier.Reference, nomenclature, tarifPublicData, {
          Reference: depart.kitBornier.Reference,
          Designation: depart.kitBornier.Designation,
        });
      }

      if (depart.selectedInterDiff && depart.selectedInterDiff?.NbModule > 0) {
        if (depart.InterDiff === 'COMMUN' && depart.TypeAlimentation === 'MONO' && !interDifferentielMono[depart.circuit - 1]) {
          addLineToNomenclature(depart.selectedInterDiff.Reference, nomenclature, tarifPublicData, {
            Reference: depart.selectedInterDiff.Reference,
            Designation: depart.selectedInterDiff.Designation,
          });
          interDifferentielMono[depart.circuit - 1] = true;
        } else if (depart.InterDiff === 'COMMUN' && depart.TypeAlimentation === 'TETRA' && !interDifferentielTetra[depart.circuit - 1]) {
          addLineToNomenclature(depart.selectedInterDiff.Reference, nomenclature, tarifPublicData, {
            Reference: depart.selectedInterDiff.Reference,
            Designation: depart.selectedInterDiff.Designation,
          });
          interDifferentielTetra[depart.circuit - 1] = true;
        } else if (depart.InterDiff === 'OUI') {
          addLineToNomenclature(depart.selectedInterDiff.Reference, nomenclature, tarifPublicData, {
            Reference: depart.selectedInterDiff.Reference,
            Designation: depart.selectedInterDiff.Designation,
          });
        }
      }
    });
  }

  if (configElectronic.parafoudre) {
    addLineToNomenclature(configElectronic.parafoudre.Reference, nomenclature, tarifPublicData, {
      Reference: configElectronic.parafoudre.Reference,
      Designation: configElectronic.parafoudre.Designation,
    });
  }

  const materielGestion = getConfigItem(configList, 'materielGestion').value;
  if (materielGestion) {
    addLineToNomenclature(materielGestion.Reference, nomenclature, tarifPublicData, {
      Reference: materielGestion.Reference,
      Designation: materielGestion.Designation,
    });
  }

  const kitEclairage = getConfigItem(configList, 'kitEclairage').value;
  if (kitEclairage) {
    addLineToNomenclature(kitEclairage.Reference, nomenclature, tarifPublicData, {
      Reference: kitEclairage.Reference,
      Designation: kitEclairage.Designation,
    });
  }

  const kitPrise = getConfigItem(configList, 'kitPrise').value;
  if (kitPrise) {
    addLineToNomenclature(kitPrise.Reference, nomenclature, tarifPublicData, {
      Reference: kitPrise.Reference,
      Designation: kitPrise.Designation,
    });
  }

  const kitBarrette = getConfigItem(configList, 'kitBarrette').value;
  if (kitBarrette) {
    addLineToNomenclature(kitBarrette.Reference, nomenclature, tarifPublicData, {
      Reference: kitBarrette.Reference,
      Designation: kitBarrette.Designation,
    });
  }

  const totalPrice = nomenclature.reduce((total, item) => (item.price ? total + item.price * item.quantity : total), 0);

  return {
    items: nomenclature,
    totalPrice,
    reducedPrice: totalPrice * Math.abs(reduction / 100 - 1),
  };
}

export function generateDossierTechniqueNomenclature(config: Configuration, tarifPublicData: TarifPublic[]): NomenclatureItem[] {
  const configElectronic = config.details;
  const configList = config.details.configurationItems;
  let nomenclature: NomenclatureItem[] = [];

  const supportTableautin = getConfigItem(configList, 'supportTableautin').value;
  if (supportTableautin.id === 'enveloppe') {
    const materielEnveloppe = getConfigItem(configList, 'materielEnveloppe').value as Enveloppe;
    nomenclature = addLineToNomenclature(materielEnveloppe.Ref1, nomenclature, tarifPublicData, undefined, materielEnveloppe.VISUELS);
    nomenclature = addLineToNomenclature(materielEnveloppe.Ref2!, nomenclature, tarifPublicData);

    console.log(nomenclature[1]);
    if (nomenclature[1].designation.toLowerCase().includes('socle')) {
      const socle = nomenclature.pop();
      nomenclature[0].socle = {
        designation: socle!.designation,
        reference: socle!.reference,
      };
    }

    nomenclature = addLineToNomenclature(materielEnveloppe.Ref3!, nomenclature, tarifPublicData);
    nomenclature = addLineToNomenclature(materielEnveloppe.Ref4!, nomenclature, tarifPublicData);
    nomenclature = addLineToNomenclature(materielEnveloppe.Ref5!, nomenclature, tarifPublicData);
  } else if (supportTableautin.id === 'platine') {
    // used only in reverse enveloppe
    const materielEnveloppe = getConfigItem(configList, 'materielEnveloppe').value as Enveloppe;
    console.log(config.details.configurationItems);
    console.log(materielEnveloppe);
    nomenclature = addLineToNomenclature(materielEnveloppe['REMISEA NEUF - PLATINE COMMANDE']!, nomenclature, tarifPublicData);

    const materielPlatineComptage = getConfigItem(configList, 'materielPlatineComptage').value;
    if (materielPlatineComptage.Ref1) {
      nomenclature = addLineToNomenclature(materielPlatineComptage.Ref1, nomenclature, tarifPublicData);
      nomenclature = addLineToNomenclature(materielPlatineComptage.Ref2, nomenclature, tarifPublicData);
    }
  } else if (supportTableautin.id === 'sans_support') {
    // used only in reverse enveloppe
  }

  // In case of S20 we also need one with an enveloppe
  const materielPlatineCommande = getConfigItem(configList, 'materielPlatineCommande').value;
  nomenclature = addLineToNomenclature(materielPlatineCommande?.Reference, nomenclature, tarifPublicData);

  const disjoncteurBranchementBesoin = getConfigItem(configList, 'disjoncteurBranchementBesoin').value;

  if (disjoncteurBranchementBesoin.id === 'oui') {
    const disjoncteurBranchement = getConfigItem(configList, 'disjoncteurBranchement').value;

    if (disjoncteurBranchement.id) {
      const { name, price, surdevis } = getTarifPublicData(disjoncteurBranchement.id, tarifPublicData);
      nomenclature.push({
        reference: disjoncteurBranchement.id,
        designation: name,
        quantity: 1,
        price,
        surdevis,
      });
    }
  }

  const kitBarrette = getConfigItem(configList, 'kitBarrette').value;
  if (kitBarrette) {
    addLineToNomenclature(kitBarrette.Reference, nomenclature, tarifPublicData, {
      Reference: kitBarrette.Reference,
      Designation: kitBarrette.Designation,
    });
  }

  return nomenclature;
}

function addLineToNomenclature(
  id: string,
  nomenclature: NomenclatureItem[],
  tarifPublicData: TarifPublic[],
  itemParameter?: Reference,
  visuel?: string,
): NomenclatureItem[] {
  let item: Reference | null;

  if (itemParameter) {
    item = itemParameter;
  } else {
    item = {
      Designation: '',
      Reference: id,
    };
  }

  if (!item?.Reference) {
    // console.warn('item not found', item);
    return nomenclature;
  }

  const { name, price, surdevis } = getTarifPublicData(item.Reference, tarifPublicData);

  const exisitingReference = nomenclature.findIndex((item) => item.reference === id);

  if (exisitingReference >= 0) {
    nomenclature[exisitingReference].quantity++;
  } else {
    nomenclature.push({
      reference: item.Reference as string,
      designation: name,
      quantity: 1,
      price: price,
      surdevis,
      visuel,
    });
  }

  return nomenclature;
}

function getTarifPublicData(id: string, tarifPublicData: TarifPublic[]): { name: string; price: number; surdevis: boolean } {
  const selectedItem = tarifPublicData.filter((item) => item.Reference == id);

  if (selectedItem.length === 1) {
    return {
      name: selectedItem[0].Designation,
      price: selectedItem[0].Prixcatalogue,
      surdevis: selectedItem[0].Surdevis === 'X',
    };
  }

  return {
    name: 'Prix non renseigné',
    price: 0,
    surdevis: false,
  };
}

export function getConfigItem(configList: ConfigurationItem[], id: ConfigId): ConfigurationItem {
  const index = configList.findIndex((item) => item.id === id);

  if (index < 0) {
    throw Error(`Config Item not found: ${id}`);
  } else {
    return configList[index];
  }
}
