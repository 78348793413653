import { Injectable } from '@angular/core';
import { Depart, KitWithModules, MaterielGestion, Tableautin } from '@depagne/types';
import { ConfigurationElectronicDetails, ConfiguredDepart } from '@depagne/types';
import { getConfigItem } from '@depagne/utils';

@Injectable({
  providedIn: 'root',
})
export class TableautinService {
  id = 0;
  constructor() {}

  validateTableautin(
    tableautin: Tableautin,
    config: ConfigurationElectronicDetails,
  ): { valide: boolean; tableatinAsObject?: Array<{ nbModules: number; name: string; id: any }[]>; dossierNomenclature?: any } {
    this.id = 0;
    const dossierNomenclature = [];
    const range = Array.from({ length: tableautin.NbRangee }).map(() => tableautin.NbModuleParRangee);
    const tableautinDraw = Array.from({ length: tableautin.NbRangee }).map(() => '');
    const tableautinAsObject: Array<{ nbModules: number; name: string; id: number }[]> = Array.from({ length: tableautin.NbRangee }).map(
      () => [],
    );

    range[0] = range[0] - tableautin.NbModuleOccupeRangee1;
    range[1] = range[1] - tableautin.NbModuleOccupeRangee2;
    tableautinDraw[0] = this.drawInRange('', 'O', tableautin.NbModuleOccupeRangee1);
    tableautinDraw[1] = this.drawInRange('', 'O', tableautin.NbModuleOccupeRangee2);

    const occupiedMessage = 'inter sectionneur, contacteur(s), protection commande et emplacement horloge';

    if (tableautin.NbModuleOccupeRangee1 > 0) {
      this.id++;
      tableautinAsObject[0] = this.drawInObjectRange(tableautinAsObject[0], occupiedMessage, tableautin.NbModuleOccupeRangee1, this.id);
      dossierNomenclature.push({
        type: 'normal',
        object: {
          designation: occupiedMessage,
        },
        id: this.id,
      });
    }
    if (tableautin.NbModuleOccupeRangee2 > 0) {
      this.id++;
      tableautinAsObject[1] = this.drawInObjectRange(tableautinAsObject[1], occupiedMessage, tableautin.NbModuleOccupeRangee2, this.id);
      dossierNomenclature.push({
        type: 'normal',
        object: {
          designation: occupiedMessage,
        },
        id: this.id,
      });
    }

    const materielGestion = getConfigItem(config.configurationItems, 'materielGestion').value as MaterielGestion;

    if (materielGestion) {
      dossierNomenclature.push({
        type: 'normal',
        object: {
          designation: materielGestion.Designation,
          reference: materielGestion.Reference,
        },
        id: 1,
      });
    }

    const nbModulesReserve = getConfigItem(config.configurationItems, 'nbModulesReserve').value as {
      id: 'nb' | 'percent';
      value: number | number[];
    };

    // parafoudre
    if (config.parafoudre) {
      let firstBlock = config.parafoudre.nbModules;
      let secondBlock = 0;

      if (config.parafoudre.nbModules === 16) {
        firstBlock = 12;
        secondBlock = 4;
      }
      if (config.parafoudre.nbModules === 12) {
        firstBlock = 8;
        secondBlock = 4;
      }

      if (firstBlock <= range[1]) {
        range[1] = range[1] - firstBlock;
        tableautinDraw[1] = this.drawInRange(tableautinDraw[1], 'F', firstBlock);
        this.id++;
        tableautinAsObject[1] = this.drawInObjectRange(tableautinAsObject[1], 'Parafoudre', firstBlock, this.id);
        dossierNomenclature.push({
          type: 'normal',
          object: {
            reference: config.parafoudre.Reference,
            designation: config.parafoudre.Designation,
          },
          id: this.id,
        });

        if (secondBlock > 0) {
          this.id--;
        }
      } else {
        console.log(`couldn't place parafoudre`, tableautin.Designation, range);
        return { valide: false };
      }

      if (
        config.parafoudre &&
        secondBlock > 0 &&
        !this.tryPlace(range, secondBlock, tableautinDraw, 'F', tableautinAsObject, 'Parafoudre', [], {
          type: 'normal',
          object: {
            reference: config.parafoudre.Reference,
            designation: config.parafoudre.Designation,
          },
        })
      ) {
        console.log(`couldn't place parafoudre`, tableautin.Designation, range);
        return { valide: false };
      }
    }

    if (nbModulesReserve.id === 'nb') {
      if ((nbModulesReserve.value as number[]).length !== 4) {
        nbModulesReserve.value = [0, 0, 0, 0];
      }

      for (let i = 0; (nbModulesReserve.value as number[]).length > i; i++) {
        if ((nbModulesReserve.value as number[])[i] > 0 && (nbModulesReserve.value as number[])[i] <= range[i + 1]) {
          range[i + 1] = range[i + 1] - (nbModulesReserve.value as number[])[i];
          tableautinDraw[i + 1] = this.drawInRange(tableautinDraw[i + 1], 'R', (nbModulesReserve.value as number[])[i]);
          this.id++;
          tableautinAsObject[i + 1] = this.drawInObjectRange(
            tableautinAsObject[i + 1],
            'modules reservés',
            (nbModulesReserve.value as number[])[i],
            'R',
          );
          dossierNomenclature.push({
            type: 'reservation',
            object: {
              designation: `modules reservés rangée ${i + 1}: ${(nbModulesReserve.value as number[])[i]}`,
            },
            id: 'R',
          });
        } else if ((nbModulesReserve.value as number[])[i] > 0) {
          console.log(`couldn't place reserved modules`, tableautin.Designation, range);
          return { valide: false };
        }
      }
    }

    // kitEclairage & kitPrise
    const kitEclairage = getConfigItem(config.configurationItems, 'kitEclairage').value as KitWithModules;
    const kitPrise = getConfigItem(config.configurationItems, 'kitPrise').value as KitWithModules;

    if (kitEclairage?.nbmodules > kitPrise?.nbmodules) {
      if (
        kitEclairage &&
        kitEclairage.id !== 'aucune' &&
        !this.tryPlace(range, kitEclairage.nbmodules, tableautinDraw, 'E', tableautinAsObject, 'Kit Eclairage', dossierNomenclature, {
          type: 'normal',
          object: {
            reference: kitEclairage.Reference,
            designation: kitEclairage.Designation,
          },
        })
      ) {
        console.log(`couldn't place kitEclairage`, tableautin.Designation, range);
        return { valide: false };
      }
      if (
        kitPrise &&
        kitPrise.id !== 'aucune' &&
        kitPrise.id !== 'aucune' &&
        !this.tryPlace(range, kitPrise.nbmodules, tableautinDraw, 'P', tableautinAsObject, 'Ki Prise', dossierNomenclature, {
          type: 'normal',
          object: {
            reference: kitPrise.Reference,
            designation: kitPrise.Designation,
          },
        })
      ) {
        console.log(`couldn't place kitPrise`, tableautin.Designation, range);
        return { valide: false };
      }
    } else {
      if (
        kitPrise &&
        kitPrise.id !== 'aucune' &&
        !this.tryPlace(range, kitPrise.nbmodules, tableautinDraw, 'P', tableautinAsObject, 'Kit Prise', dossierNomenclature, {
          type: 'normal',
          object: {
            reference: kitPrise.Reference,
            designation: kitPrise.Designation,
          },
        })
      ) {
        console.log(`couldn't place kitPrise`, tableautin.Designation, range);
        return { valide: false };
      }
      if (
        kitEclairage &&
        kitEclairage.id !== 'aucune' &&
        !this.tryPlace(range, kitEclairage.nbmodules, tableautinDraw, 'E', tableautinAsObject, 'Kit Eclairage', dossierNomenclature, {
          type: 'normal',
          object: {
            reference: kitEclairage.Reference,
            designation: kitEclairage.Designation,
          },
        })
      ) {
        console.log(`couldn't place kitEclairage`, tableautin.Designation, range);
        return { valide: false };
      }
    }

    // Departs, place from largest to smallest module
    const departs = getConfigItem(config.configurationItems, 'departs').value as ConfiguredDepart[];
    const interDifferentielMono = Array.from({ length: tableautin.NbRangee }).map(() => false);
    const interDifferentielTetra = Array.from({ length: tableautin.NbRangee }).map(() => false);
    let departModules = departs.reduce(
      (
        modules: { type: string; nbModules: number; name: string; depart: ConfiguredDepart; reference: string; visuel?: string }[],
        depart,
        currentIndex,
      ) => {
        modules.push({
          type: (currentIndex + 1).toString(),
          name: `Depart ${(currentIndex + 1).toString()}: ${depart.Designation}`,
          nbModules: depart.NbModule,
          depart,
          reference: depart.Reference,
          visuel: depart.VISUELS,
        });

        if (depart.besoinBornier && depart.kitBornier) {
          modules.push({
            type: 'B',
            nbModules: depart.kitBornier?.NbModules,
            name: depart.kitBornier.Designation,
            depart,
            reference: depart.kitBornier.Reference,
          });
        }

        if (depart.selectedInterDiff && depart.selectedInterDiff?.NbModule > 0) {
          if (depart.InterDiff === 'COMMUN' && depart.TypeAlimentation === 'MONO' && !interDifferentielMono[depart.circuit - 1]) {
            modules.push({
              type: 'I',
              nbModules: depart.selectedInterDiff.NbModule,
              name: depart.selectedInterDiff.Designation,
              depart,
              reference: depart.selectedInterDiff.Reference,
            });
            interDifferentielMono[depart.circuit - 1] = true;
          } else if (depart.InterDiff === 'COMMUN' && depart.TypeAlimentation === 'TETRA' && !interDifferentielTetra[depart.circuit - 1]) {
            modules.push({
              type: 'I',
              nbModules: depart.selectedInterDiff.NbModule,
              name: depart.selectedInterDiff.Designation,
              depart,
              reference: depart.selectedInterDiff.Reference,
            });
            interDifferentielTetra[depart.circuit - 1] = true;
          } else if (depart.InterDiff === 'OUI') {
            modules.push({
              type: 'I',
              nbModules: depart.selectedInterDiff.NbModule,
              name: depart.selectedInterDiff.Designation,
              depart,
              reference: depart.selectedInterDiff.Reference,
            });
          }
        }

        return modules;
      },
      [],
    );

    console.log(JSON.parse(JSON.stringify(departModules)));

    departModules = departModules.sort((a, b) => {
      if (a.nbModules > b.nbModules) return -1;
      if (a.nbModules < b.nbModules) return 1;
      return 0;
    });

    for (let i = 0; i < departModules.length; i++) {
      if (
        !this.tryPlace(
          range,
          departModules[i].nbModules,
          tableautinDraw,
          departModules[i].type,
          tableautinAsObject,
          departModules[i].name,
          dossierNomenclature,
          {
            type: departModules[i].type === 'B' ? 'bornier' : departModules[i].type === 'I' ? 'interdiff' : 'depart',
            object: {
              reference: departModules[i].reference,
              designation: departModules[i].name,
              depart: departModules[i].depart,
              visuel: departModules[i].visuel,
            },
          },
        )
      ) {
        console.log(`couldn't place departModule of type ${departModules[i].type}`, tableautin.Designation, range);
        return { valide: false };
      }
    }

    let nbModulesLeft = range.reduce((left, row) => left + row, 0);

    let reservedModules = Math.floor((tableautin.NbRangee * tableautin.NbModuleParRangee * (nbModulesReserve.value as number)) / 100);
    if (nbModulesReserve.id === 'percent' && nbModulesLeft >= reservedModules) {
      for (let i = 0; tableautin.NbRangee - 1 > i; i++) {
        if (reservedModules > 0 && range[i] > 0) {
          let reserve = 0;
          if (range[i] <= reservedModules) {
            reserve = range[i];
          } else {
            reserve = reservedModules;
          }

          range[i] = range[i] - reserve;
          reservedModules = reservedModules - reserve;

          tableautinDraw[i] = this.drawInRange(tableautinDraw[i], 'R', reserve);
          tableautinAsObject[i] = this.drawInObjectRange(tableautinAsObject[i], 'modules reservés', reserve, 'R');
          dossierNomenclature.push({
            type: 'reservation',
            object: {
              designation: `modules reservés rangée ${i + 1}: ${reserve} modules`,
            },
            id: 'R',
          });
        }
      }
    } else if (nbModulesReserve.id === 'percent') {
      console.log(`couldn't place nbModulesReserve percent`, tableautin.Designation, nbModulesLeft);
      return { valide: false };
    }

    range.forEach((row, index) => {
      tableautinDraw[index] = this.drawInRange(tableautinDraw[index], 'R', row);
    });

    // console.log(`modules left`, tableautin.Designation, nbModulesLeft);
    // console.log(tableautinDraw);
    console.log(tableautinAsObject);
    return { valide: true, tableatinAsObject: tableautinAsObject, dossierNomenclature };
  }

  private tryPlace(
    range: number[],
    nbModules: number,
    tableatinDraw: string[],
    type: string,
    tableatinAsObject: Array<{ nbModules: number; name: string; id: number }[]>,
    objectName: string,
    dossierNomenclature: any,
    objectDossierNomenclature: any,
    reverse = false,
  ) {
    if (type === 'B') {
      reverse = true;
    }

    if (!reverse) {
      for (let i = 0; i < range.length; i++) {
        if (range[i] >= nbModules) {
          range[i] = range[i] - nbModules;
          tableatinDraw[i] = this.drawInRange(tableatinDraw[i], type, nbModules);
          this.id++;
          tableatinAsObject[i] = this.drawInObjectRange(tableatinAsObject[i], objectName, nbModules, this.id);
          dossierNomenclature.push({ ...objectDossierNomenclature, id: this.id });
          return true;
        }
      }
    } else {
      for (let i = range.length - 1; i > 0; i--) {
        if (range[i] >= nbModules) {
          range[i] = range[i] - nbModules;
          tableatinDraw[i] = this.drawInRange(tableatinDraw[i], type, nbModules);
          this.id++;
          tableatinAsObject[i] = this.drawInObjectRange(tableatinAsObject[i], objectName, nbModules, this.id);
          dossierNomenclature.push({ ...objectDossierNomenclature, id: this.id });
          return true;
        }
      }
    }

    return false;
  }

  drawInRange(row: string, type: string, nbModules: number) {
    return row + type.repeat(nbModules);
  }
  drawInObjectRange(row: { nbModules: number; name: string; id: number }[], name: string, nbModules: number, id: any) {
    return [
      ...row,
      {
        nbModules,
        name,
        id,
      },
    ];
  }
}
