<h2>Enveloppes</h2>
<p *ngFor="let item of data.enveloppeData">
  {{item.id}}<br />{{item.Designation}}<br />{{item.VISUELS}}<img src="assets/images/technical/{{item.VISUELS}}.png" />
</p>
<h2>Tableautins</h2>
<p *ngFor="let item of data.tableautinData">
  {{item.id}}<br />{{item.Designation}}<br />{{item.VISUELS}}<img src="assets/images/technical/tableautin/{{item.VISUELS}}.jpg" />
</p>
<p *ngFor="let item of data.platineCommandeData">
  {{item.id}}<br />{{item.Designation}} <img src="assets/images/technical/{{item.id}}.png" />
</p>
<h2>Disjoncteurs</h2>
<p *ngFor="let item of data.disjoncteurData">
  {{item.id}}<br />{{item.Designation}}<br />
  {{item.TypeAlimentation}} <img src="assets/images/technical/{{item.TypeAlimentation}}-{{item.id}}.jpg" />
</p>
<h2>Kits Barrette</h2>
<p *ngFor="let item of data.kitBarretteData">{{item.id}}<br />{{item.Designation}} <img src="assets/images/technical/{{item.id}}.jpg" /></p>
<h2>Kits Eclairage</h2>
<p *ngFor="let item of data.kitEclairageData">
  {{item.id}}<br />{{item.Designation}} <img src="assets/images/technical/{{item.id}}.jpg" />
</p>
<h2>Kits Prise</h2>
<p *ngFor="let item of data.kitPriseData">{{item.id}}<br />{{item.Designation}} <img src="assets/images/technical/{{item.id}}.jpg" /></p>
<h2>Parafoudres</h2>
<p *ngFor="let item of data.parafoudreData">{{item.id}}<br />{{item.Designation}} <img src="assets/images/technical/{{item.id}}.jpg" /></p>
<h2>Materiel Gestion</h2>
<p *ngFor="let item of data.materielGestionData">
  {{item.id}}<br />{{item.Designation}} <img src="assets/images/technical/{{item.id}}.jpg" />
</p>
<h2>Departs</h2>
<p *ngFor="let item of data.departData">{{item.id}}<br />{{item.Designation}} <img src="assets/images/technical/{{item.VISUELS}}.jpg" /></p>

<h2>Interdiffs</h2>
<p *ngFor="let item of data.interDiffData">{{item.id}}<br />{{item.Designation}} <img src="assets/images/technical/{{item.id}}.jpg" /></p>
